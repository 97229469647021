import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import emailSVG from "../images/email.svg"
import locationSVG from "../images/location.svg"
import addToMailchimp from "gatsby-plugin-mailchimp"
import getImages from "../utils/getImages"

const NotFoundPage = ({ data, location }) => {
  const { t } = useTranslation()
  const siteTitle = data.site.siteMetadata.title

  const [value, setValue] = useState("")
  const [success, setSuccess] = useState(false)

  const saveEmail = async () => {
    if (value?.length > 0) {
      try {
        const result = await addToMailchimp(value, null, null)
        console.log("result mailchimp", result)
        setSuccess(result?.result === "success")
      } catch (error) {
        console.error("Error enviando email", error)
      }
    }
  }

  const images = getImages(
    getImage(data.mobileBackground),
    getImage(data.tabletBackground),
    getImage(data.desktopBackground)
  )

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={t("contact.title")} />
      <div className="page">
        <GatsbyImage
          className="editorial--background"
          image={images}
          alt={"Background image"}
        />
        <div className="page-center">
          <div className="contact-container">
            <div className="contact-form">
              <p
                className="page--text"
                dangerouslySetInnerHTML={{ __html: t("contact.message1") }}
              />
              <p
                className="page--text"
                dangerouslySetInnerHTML={{ __html: t("contact.message2") }}
              />
              <div className="page--input">
                <label className="page--input-label" htmlFor="emailInput">
                  {t("contact.email")}
                </label>
                <input
                  id="emailInput"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  type="email"
                ></input>
                <div className="page--cta-container">
                  {success ? (
                    <span className="page--cta">{t("contact.thanks")}</span>
                  ) : (
                    <span className="page--cta" onClick={saveEmail}>
                      {t("contact.cta")}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="contact-list">
              <a
                className="contact-list-element"
                href="https://goo.gl/maps/qk27feordhdDbRdJ8"
              >
                <img src={locationSVG} alt="Address" className="contact-icon" />
                <span className="contact-list-label">
                  {t("contact.address")}
                </span>
              </a>

              <a
                className="contact-list-element"
                href="mailto:hola@ardaraz.com"
              >
                <img src={emailSVG} alt="Email" className="contact-icon" />
                <span className="contact-list-label">
                  {t("contact.contactEmail")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    mobileBackground: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "contact-background" }
      extension: { eq: "jpg" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    tabletBackground: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "contact-background-tablet" }
      extension: { eq: "jpg" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    desktopBackground: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "contact-background-desktop" }
      extension: { eq: "jpg" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
